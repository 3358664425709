import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translations from "./translations.json";
import LanguageDetector from 'i18next-browser-languagedetector';

import imports from './imports';

// Import des ressources de traduction de CWA
// !! Si des clés de traduction sont en doublon dans les imports, seule la dernière rencontrés est conservée
// !! Si des clés de traduction sont en doublon entre les traductions de ce projet et les imports, les imports prennent le dessus
const compileImports = (imports, res) => {
    const langs = imports.langs;
    
    for (const key in imports.resources) {
        const dict = imports.resources[key];

        for (const translationKey in dict.Translations) {
            const elem = dict.Translations[translationKey];

            for (const langKey in langs) {
                const translation = elem[langKey];
                res[langs[langKey]].translation[translationKey] = translation;
            }
        }
    }

    return res;
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: compileImports(imports, translations),
        fallbackLng: 'fr',

        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;
