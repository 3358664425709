// L'URL de l'API peut être modifiée dans le fichier public/config.js
export const API_URL = window.config.apiUrl;

const KEY = "API_TOKEN";

let hasFailedBefore = false;

export function setToken(token) {
	try {
		return localStorage.setItem(KEY, token);
	} catch (err) {
		console.log({ hasFailedBefore });
		if (!hasFailedBefore) {
			hasFailedBefore = true;
			alert(
				"Notre site internet n’utilise que des cookies fonctionnels permettant de vous proposer l’accès à nos services.\nVeuillez les activer pour pouvoir vous connecter."
			);
		}
	}
}

export function getToken() {
	try {
		return localStorage.getItem(KEY);
	} catch (err) {
		if (!hasFailedBefore) {
			console.log({ hasFailedBefore });
			hasFailedBefore = true;
			alert(
				"Notre site internet n’utilise que des cookies fonctionnels permettant de vous proposer l’accès à nos services.\nVeuillez les activer pour pouvoir vous connecter."
			);
		}
	}
}

export const isLoggedIn = () =>
	getToken() !== "" && getToken() !== null && getToken() !== undefined;

export const removeToken = () => localStorage.removeItem(KEY);

// https://stackoverflow.com/a/38552302/5077092
export const parseJwt = (token) => {
	// Récupération du token automatique
	if (typeof token !== "string") token = getToken();

	// Si la personne est connecté
	if (token !== null) {
		var base64Url = token.split(".")[1];
		var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
		var jsonPayload = decodeURIComponent(
			window
				.atob(base64)
				.split("")
				.map(function (c) {
					return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join("")
		);
		return JSON.parse(jsonPayload);
	} else return null;
};
